import request from "@/utils/request";

const api = {
  downloadUrl: "/system/app-upgrade/download", // 获取不同手机类型下载App 的链接地址
};

/**
 * 提交支付
 * @param {*} parameter
 */
export function getDownloadUrl(parameter) {
  return request.get(api.downloadUrl, parameter);
}
